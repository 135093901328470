<template>
  <div class="registration pb-4">
    <div class="d-flex justify-content-between">
      <h3 class="pb-3 px-1 mb-0">Add Facility Details</h3>
    </div>
    <AddFacilityDetails :customer="2" @submit="create" />
  </div>
</template>
<script>
import AddFacilityDetails from "@/containers/Facility/AddFacilityDetails";

import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    AddFacilityDetails
  },
  name: "FacilityDetail",
  methods: {
    ...mapActions(["addFacilityDetails"]),
    create(facility) {
      this.addFacilityDetails(facility);
    }
  }
};
</script>
